import React from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { Form } from "skillstrainer-resource-library"
import RegisterFormBuilder from "../formBuilder/user-interest-form-builder"

export default function SpeakerForm() {
  const handleSubmit = async register => {
    console.log("regisyer-==", register)
    const payload = {
      data: {
        entity: {
          type: "user-interest",
          eventId: 1,
        },
        operation: "insert",

        data: {
          form_type: "speaker",
          full_name: register.full_name,
          designation: register.designation,
          company_name: register.company_name,
          email: register.email,
          phone_number: register.phone_number,
          country: register.country,
          area_of_interest: register.area_of_interest,
          resume: register.resume[0]?.url,
        },
      },
    }

    try {
      const result = await axios.post(
        `${process.env.URL}/api/user-interests`,
        payload
      )

      if (result.status === 200) {
        return toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        toast.error("Something gone wrong!", {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (err) {
      console.log("I am error", err)
      return toast.error("Something gone wrong!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <h1 className="heading mb-3">Register As A Speaker</h1>
        <ToastContainer />

        <Form
          formBuilder={RegisterFormBuilder}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          plugins={{
            file: {
              services: {
                uploadFn: async fileData => {
                  // * strapi upload logic
                  const formData = new FormData()
                  formData.append("files", fileData)
                  return await axios
                    .post(`${process.env.URL}/api/upload`, formData)
                    .then(({ data }) => {
                      return data[0].id
                    })
                },
              },
            },
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  )
}
