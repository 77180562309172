import { useState, useEffect } from "react"
import Countries from "../pages/countries.json"
import * as yup from "yup"

function RegisterFormBuilder(values) {
  const fileSchema = yup.array().of(yup.object()).nullable()
  const [schema, setSchema] = useState({})

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Full Name",
        type: "text",
        required: true,
      },
      designation: {
        label: "Designation",
        type: "text",
        required: true,
      },
      company_name: {
        label: "Company Name",
        type: "text",
        required: true,
      },
      email: {
        label: "Email",
        type: "email",
        required: true,
      },
      phone_number: {
        label: "Phone Number",
        type: "text",
        required: true,
      },
      country: {
        label: "Country",
        type: "select",
        required: true,
        options: Countries.map(country => ({
          value: country.name,
          label: country.name,
        })),
      },
      join_as_a: {
        label: "Join",
        type: "select",
        required: true,
        options: [
          {
            value: "speaker",
            label: "Speaker",
          },
          {
            value: "sponsor",
            label: "Sponsor",
          },
          {
            value: "upcoming-event",
            label: "Upcoming Event",
          },
        ],
      },
    }

    if (values.join_as_a === "speaker") {
      schema.resume = {
        label: "Upload Your Resume",
        type: "file",
        schema: fileSchema,
      }
      schema.area_of_interest = {
        label: "Area of Interest",
        type: "select",
        required: true,
        options: [
          {
            value: "Government",
            label: "Government",
          },
          {
            value: "Environment",
            label: "Environment",
          },
          {
            value: "Social",
            label: "Social",
          },
          {
            value: "Others",
            label: "Others",
          },
        ],
      }
    }

    if (values.join_as_a === "sponsor") {
      schema.category = {
        label: "Category",
        type: "select",
        required: true,
        options: [
          {
            value: "category1",
            label:
              "Category 1 (Strategic Partner, Corporate Partner, State Host)",
          },
          {
            value: "category2",
            label:
              "Category 2 (Sectoral Corporate Partner, Sectoral Skilling Partner)",
          },
          {
            value: "category3",
            label: "Category 3 (NGO Partner, MSME Partner)",
          },
        ],
      }
      // schema.coupon = {
      //   label: "Coupon",
      //   type: "text",
      // }
    }

    if (values.join_as_a === "upcoming-event") {
      schema.location = {
        label: "Location",
        required: true,
        type: "select",
        options: [
          {
            value: "Bangalore",
            label: "Bangalore",
          },
          {
            value: "Chandigarh",
            label: "Chandigarh",
          },
          {
            value: "Guwahati",
            label: "Guwahati",
          },
          {
            value: "Goa",
            label: "Goa",
          },
          {
            value: "Puducherry",
            label: "Puducherry",
          },
          {
            value: "New Delhi",
            label: "New Delhi",
          },
        ],
      }
    }
    setSchema(schema)
  }, [values])

  return schema
}

export default RegisterFormBuilder
